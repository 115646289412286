import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Signin, Signup, NotFound, Dashboard, Payment, SensoreView } from "../components";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { UserContext } from "../contexts/user";
import Test from "../components/Test";

export default () => {
  const user = useContext(UserContext);
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={Signin} auth = {user} />
        <PublicRoute exact path="/signup" component={Signup}auth = {user} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} auth = {user} />
        <PrivateRoute exact path="/payment" component={Payment} auth = {user} />
        <PrivateRoute exact path="/view-sensor" component={SensoreView} auth = {user} />
        <PrivateRoute exact path="/test" component={Test} auth = {user} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

import React, { useState, useEffect } from "react";
import StripeCheckout from "react-stripe-checkout";
import { Grid, Input, Label, Segment } from "semantic-ui-react";
import { stripePublicKey } from '../../../config'


const Payment = () => {
  const [price, setPrice] = useState(10);
  const [quantity, setQuantity] = useState(1);

  // const stripePublicKey =
  //   process.env.STRIPE_PUBLIC_KEY ||
  //   "pk_test_mTbz95I1kySBRX0yUeWKQPYR00tF3MUeFG";

  const handleQuantity = e => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setQuantity(value);
    }
  };
  useEffect(() => {
    if (quantity) {
      setPrice(quantity * 10);
    } else {
      setPrice(10);
    }
  }, [quantity]);
  const handleToken = (token, addresses) => {
    //will processed by backend
  };
  return (
    <React.Fragment>
      <div id="signin">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <div className="payment">
              <div className="payment-inner">
                <p>
                  Default Price:{" "}
                  <span style={{ marginLeft: "5px", display: "inline-block" }}>
                    $10
                  </span>
                </p>
                <Segment>
                  <Input labelPosition="right" type="text" placeholder="Amount">
                    <Label basic>$</Label>
                    <input value={price} />
                    <Label>.00</Label>
                  </Input>
                </Segment>
                <Segment>
                  <Input labelPosition="right" type="text" placeholder="Amount">
                    <Label basic>Q</Label>
                    <input value={quantity} onChange={e => handleQuantity(e)} />
                    <Label basic></Label>
                  </Input>
                </Segment>
                <Segment>
                  <StripeCheckout
                    stripeKey={stripePublicKey}
                    token={handleToken}
                    amount={price}
                    name={"Purchasing Map Token"}
                    billingAddress
                    shippingAddress
                  />
                </Segment>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Payment;

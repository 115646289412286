const firebaseConfig = {
  apiKey: "AIzaSyDyLv4M4zSFfxxA9tVX8nCowygiVgg68jA",
  authDomain: "finditly-dash-demo.firebaseapp.com",
  databaseURL: "https://finditly-dash-demo.firebaseio.com",
  projectId: "finditly-dash-demo",
  storageBucket: "finditly-dash-demo.appspot.com",
  messagingSenderId: "177292988163",
  appId: "1:177292988163:web:6339ea7808ba8fa65ea336",
  measurementId: "G-DBPKNZHNHW"
};

export default firebaseConfig
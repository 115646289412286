import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Grid, Header, Segment, Loader } from "semantic-ui-react";
import { UserContext } from "../../contexts/user";

const Signup = props => {
  const user = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    user.sighUp({
      email,
      password,
      userName,
      history: props.history,
      setLoading
    });
  };
  return (
    <React.Fragment>
      <div id="signin">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form
              size="large"
              onSubmit={handleSubmit}
              style={{ padding: "30px 0" }}
            >
              <Segment stacked>
                <Header textAlign="center">
                  <div className="logo">
                    <img src="./img/logo.png" alt="logo" />
                  </div>
                </Header>
                <Header as="h2" textAlign="center">
                  Create Account
                </Header>
                <div className="para">
                  <p>Please enter your email and password to continue</p>
                </div>
                <Form.Input
                  label="Name:"
                  placeholder="Name"
                  name="userName"
                  onChange={e => setUserName(e.target.value)}
                />
                <Form.Input
                  label="Email Address"
                  placeholder="E-mail address"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                />
                <Form.Input
                  label="Password"
                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                  type="password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className="ui checkbox">
                  <input type="checkbox" name="example" required />
                  <label>I accept terms &amp; condition</label>
                </div>
                <Button fluid>
                  {loading ? (
                    <Loader size="mini" active inline />
                  ) : (
                    <span>Sign Up</span>
                  )}
                </Button>
                <div className="already">
                  <p>
                    Already have an account? <Link to="/">Login</Link>
                  </p>
                </div>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Signup;

import React, { useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { googleMapApi } from "../../../config";

const CustomMarker = ({ item }) => {
  const [showToltip, setToltip] = useState(false);
  return (
    <Marker
      position={{
        lat: item.latitude,
        lng: item.longitude,
      }}
      onMouseOver={() => setToltip(true)}
      onMouseOut={() => setToltip(false)}
    >
      {showToltip && (
        <InfoWindow>
          <p style={{fontSize: '15px'}}>
          <span style={{ fontWeight: "bold" }}>Latitude: </span>
            {item.latitude}
            <br />
            <span style={{ fontWeight: "bold" }}>Longitude: </span>
            {item.longitude}
            <br />
            <span style={{ fontWeight: "bold" }}>Temperature: </span>
            {item.temperature} &#8451; (
            {Number((item.temperature * 9) / 5 + 32).toFixed(2)} &#8457;)
            <br />
            <span style={{ fontWeight: "bold" }}>Humidity: </span>
            {item.humidity}
            <br />
            <span style={{ fontWeight: "bold" }}>Pressure: </span>
            {item.pressure}
          </p>
        </InfoWindow>
      )}
    </Marker>
  );
};
const MyMapComponent = (props) => {
  const [showToltip, setToltip] = useState(false);

  return (
    <GoogleMap
      defaultZoom={8}
      center={{ lat: props.centerMarker.lat, lng: props.centerMarker.lng }}
    >
      {props.markerData.lenght !== 0 &&
        props.markerData.map((item) => (
          <CustomMarker key={item.company_id} item={item} />
        ))}
      <Marker
        position={{
          lat: props.centerMarker.lat,
          lng: props.centerMarker.lng,
        }}
        onMouseOver={() => setToltip(true)}
        onMouseOut={() => setToltip(false)}
      >
        {showToltip && (
          <InfoWindow>
            <p>Default Marker</p>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(MyMapComponent));

export default (props) => {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <WrappedMap
        centerMarker={props.centerMarker}
        markerData={props.markerData}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleMapApi}`}
        loadingElement={<div style={{ height: "100%" }}></div>}
        containerElement={<div style={{ height: "100%" }}></div>}
        mapElement={<div style={{ height: "100%" }}></div>}
      />
    </div>
  );
};

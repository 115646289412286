import React from "react";
import MainRouter from './route/Route' 
import { toast } from 'react-toastify';
import "semantic-ui-css/semantic.min.css";
import '../app/styles/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import UserContextProvider from "./contexts/user";

toast.configure()

class App extends React.Component {
  render() {
    return (
      <UserContextProvider>
        <MainRouter />
      </UserContextProvider>
    );
  }
}
export default App;

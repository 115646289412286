import React, { useEffect, useState, Fragment, useContext } from "react";
import { format } from "date-fns";
import { firebaseStore, firebaseAuth } from "../../config";
import {
  Header,
  Grid,
  Table,
  Container,
} from "semantic-ui-react";
import { UserContext } from "../contexts/user";
import { Link } from "react-router-dom";

const LoaderTable = () => (
  <Table.Body
    active="true"
    className="ui segment ui active inverted dimmer"
  >
    <Table.Row className="ui loader"></Table.Row>
  </Table.Body>
);

const SensoreView = () => {
  const [data, setData] = useState(undefined);
  const user = useContext(UserContext).user;
  
  useEffect(() => {
    firebaseStore
      .collection("data")
      // .where("company_id", "==",  user.uid)
      .onSnapshot({ includeMetadataChanges: true }, async (snap) => {
        let info = [];
        let tracker = {};
        for (const doc of snap.docs) {
          info.push({
            ...doc.data(),
            id: doc.id,
          });
        }
        setData(info || []);
      });
  }, []);

  return (
    <div id="sensore">
      <Grid textAlign="center" style={{ minHeight: "103vh" }}>
        <Grid.Column textAlign="center">
          <Header textAlign="center">
            <div className="logo" style={{ marginTop: "40px" }}>
              <img src="./img/logo.png" alt="logo" />
            </div>
          </Header>
          <Container style={{ overflowX: "auto" }}>
           <Link className="go-back" to="/">Go Back</Link>
            <Table celled textAlign="center">
              {data === undefined ? (
                <LoaderTable />
              ) : (
                <Fragment>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>timestamp</Table.HeaderCell>
                      <Table.HeaderCell>tracker_id</Table.HeaderCell>
                      <Table.HeaderCell>tracker_name</Table.HeaderCell>
                      <Table.HeaderCell>latitude</Table.HeaderCell>
                      <Table.HeaderCell>longitude</Table.HeaderCell>
                      <Table.HeaderCell>temperature</Table.HeaderCell>
                      <Table.HeaderCell>humidity</Table.HeaderCell>
                      <Table.HeaderCell>pressure</Table.HeaderCell>
                      {/* <Table.HeaderCell>company_id</Table.HeaderCell> */}
                      {/* <Table.HeaderCell>access_level</Table.HeaderCell> */}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.length === 0 ? (
                      <Table.Row className="data-not-found">
                        Sensor Data is not found
                      </Table.Row>
                    ) : (
                      data.map((item, index) => (
                          <Table.Row key={item.id}>
                            <Table.Cell>{format(Number(item.timestamp || ''), 'PP')}</Table.Cell>
                            <Table.Cell>{item.tracker_id}</Table.Cell>
                            <Table.Cell>{item.tracker_name}</Table.Cell>
                            <Table.Cell>{item.latitude}</Table.Cell>
                            <Table.Cell>{item.longitude}</Table.Cell>
                            <Table.Cell>{item.temperature}</Table.Cell>
                            <Table.Cell>{item.humidity}</Table.Cell>
                            <Table.Cell>{item.pressure}</Table.Cell>
                            {/* <Table.Cell>{item.company_id}</Table.Cell> */}
                            {/* <Table.Cell>{item.access_level}</Table.Cell> */}
                          </Table.Row>
                        )
                      )
                    )}
                  </Table.Body>
                </Fragment>
              )}
            </Table>
          </Container>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default SensoreView;

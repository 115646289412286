import React, { useState, useContext, useEffect } from "react";
import { Menu, Grid, Sidebar, Search, Button, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user";
import MyMapComponent from "./Map";
import request from "request";
import {
  mapboxPublicKey,
  weatherApi,
  googleMapApi,
  firebaseStore,
} from "../../../config";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import Geocode from "react-geocode";
Geocode.setApiKey(googleMapApi);

const Dashboard = () => {
  const user = useContext(UserContext);
  const [searchValue, setSearchValue] = useState("");
  const [center, setCenter] = useState([]);
  const [mapdata, setMapdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sloading, setSLoading] = useState(false);

  const onSearch = (e) => {
    setSLoading(true);
    setSearchValue(e.target.value);
    const data = filterData.filter((item)=> {
      return item.tracker_name.toLowerCase().includes(e.target.value.toLowerCase())
    } )
    setMapdata(data)
   
  };
  useEffect(()=>{
   if(mapdata && mapdata.length){
    Geocode.fromAddress(mapdata[0].tracker_name).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter([lng, lat]);
        setSLoading(false);
      },
      (error) => {
        setCenter([]);
        setSLoading(false);
        console.error(error);
      }
    );
   }
  },[mapdata])

  const handlePlace = (item) => {
    setCenter([item.longitude, item.latitude]);
  };

  //on register
  // const onRegister = async () => {
  //   setLoading(true);

  //   const weatherUrl = `http://api.openweathermap.org/data/2.5/weather?lat=${center[1]}&lon=${center[0]}&appid=f64a57927b79ede9ae84f14d0c521ece`;

  //   request({ url: weatherUrl, json: true }, async (err, response) => {
  //     if (err) {
  //       toast("Weather data isn't collected!");
  //     } else {
  //       const main = response.body.main || {};
  //       const tracker_id = uuid();
  //       const trackerData = {
  //         tracker_name: searchValue,
  //         company_id: user.user.uid,
  //         tracker_id,
  //       };
  //       const data = {
  //         latitude: center[1],
  //         longitude: center[0],
  //         pressure: main.pressure,
  //         temperature: main.temp,
  //         humidity: main.humidity,
  //         company_id: user.user.uid,
  //         tracker_name: searchValue,
  //         tracker_id,
  //         timestamp: Date.now(),
  //       };
  //       await user.saveTrackerData(data, trackerData);
  //       setSearchValue("");
  //     }
  //     setLoading(false);
  //   });
  // };
 const [filterData, setFilterData] = useState([])
  useEffect(() => {
    firebaseStore
      .collection("data")
      // .where("company_id", "==",  user.user.uid)
      .onSnapshot({ includeMetadataChanges: true }, async (snap) => {
        let info = [];
        let tracker = {};
        for (const doc of snap.docs) {
          info.push({
            ...doc.data(),
            id: doc.id,
          });
        }
        setMapdata(info || []);
        setFilterData(info || [])
      });
  }, []);
  return (
    <React.Fragment>
      <div id="dashboard">
        <Grid style={{ height: "100vh" }}>
          <Grid.Column width={3}>
            <Sidebar
              as={Menu}
              animation="push"
              icon="labeled"
              inverted
              onHide={() => {}}
              vertical
              visible={true}
            >
              <Menu.Item as="a">
                <div className="logo">
                  <img src="./img/logo.png" alt="logo" />
                  <h2
                    style={{
                      textTransform: "capitalize",
                      color: "rgba(0,0,0,.87)",
                      marginTop: "10px",
                    }}
                  >
                    {user.user.user_name || "Anonyomous"}
                  </h2>
                  {/* <p
                    style={{
                      textTransform: "capitalize",
                      color: "rgba(0,0,0,.87)",
                      marginTop: "10px",
                    }}
                  >
                    Total token: {user.user.totalToken || "Not found"}
                  </p> */}
                  {/* <Link to="/payment">Buy Token</Link> */}

                  {/* view-sensore */}
                </div>
              </Menu.Item>
              <Menu.Item as="div">
                <Search
                  input={{ icon: "search", iconPosition: "left" }}
                  loading={sloading}
                  onSearchChange={onSearch}
                  results={null}
                  value={searchValue}
                  placeholder= "Search on sensor name"
                />
              </Menu.Item>
              <Menu.Item as="div">
                <Link to="/view-sensor" className="view-sensore d-block">
                  View All Data
                </Link>
              </Menu.Item>
              {/* <Menu.Item>
                <div className="register">
                  <Button
                    onClick={onRegister}
                    disabled={loading || !searchValue}
                  >
                    {loading ? (
                      <Loader size="mini" active inline />
                    ) : (
                      <span>Register</span>
                    )}
                  </Button>
                </div>
              </Menu.Item> */}
              {mapdata.length !== 0 ? (
                mapdata.map((item, index) => (
                  <Menu.Item key={index}>
                    <div className="tag">
                      <Link
                        to="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePlace(item);
                        }}
                      >
                        <p style={{ textTransform: "capitalize" }}>
                          {item.tracker_name}
                        </p>
                      </Link>
                    </div>
                  </Menu.Item>
                ))
              ) : (
                <p>There is no tag.</p>
              )}
              <Menu.Item>
                <div className="logout">
                  <button onClick={user.logout}>Logout</button>
                </div>
              </Menu.Item>
            </Sidebar>
          </Grid.Column>
          <Grid.Column width={13}>
            <div className="map">
              <MyMapComponent
                centerMarker={{
                  lat: center[1] || 39.7640021,
                  lng: center[0] || -105.1352965,
                }}
                markerData={mapdata}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React from "react";
import { withProps, compose } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';


const Test = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBHMwkoZJQfz8dPaGXHobl_t_yKAEgQ_bg&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: 23.8103, lng: 90.4125 }}>
    {props.isMarkerShown && (
      <Marker position={{ lat: 23.8103, lng: 90.4125 }} />
    )}
    <Autocomplete
    style={{width: '90%'}}
    onPlaceSelected={(place) => {
      console.log(place);
    }}
    types={['(regions)']}
    componentRestrictions={{country: "ru"}}
/>
  </GoogleMap>
));

export default Test;

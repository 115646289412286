import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { firebaseAuth, firebaseStore } from "../../config";

const MAP_APP_LOCAL_DATA = "MAP_APP";
export const UserContext = createContext();
const UserContextProvider = (props) => {
  const findLocalStorage = () => {
    return JSON.parse(localStorage.getItem(MAP_APP_LOCAL_DATA));
  };
  const [auth, setAuth] = useState(
    findLocalStorage() || {
      isAuthenticated: false,
      user: {},
    }
  );
  const saveToLocalStorage = (payload) => {
    localStorage.setItem(MAP_APP_LOCAL_DATA, JSON.stringify(payload));
  };
  // const tagTolattitude = payload => {
  //   const mapData = auth.user.mapData.concat([
  //     {
  //       lat: payload.lat,
  //       lng: payload.lng,
  //       PlaceName: payload.PlaceName
  //     }
  //   ]);
  //   firebaseStore
  //     .collection("users")
  //     .where("uid", "==", payload.uid)
  //     .get()
  //     .then(querySnapshot => {
  //       querySnapshot.forEach(function(doc) {
  //         firebaseStore
  //           .collection("users")
  //           .doc(doc.id)
  //           .update({ mapData })
  //           .then(() => {
  //             firebaseStore
  //               .collection("users")
  //               .doc(doc.id)
  //               .onSnapshot(function(doc) {
  //                 payload.setLoading(false);
  //                 payload.setSearchValue('')
  //                 payload.setCenter([])
  //                 setAuth({ isAuthenticated: true, user: doc.data() });
  //                 saveToLocalStorage({ isAuthenticated: true, user: doc.data() });
  //               });
  //           });
  //       });
  //     })
  //     .catch(error => {
  //       toast(error.message);
  //       payload.setLoading(false);
  //     });
  // };

  const saveTrackerData = async (data, trackerData) => {
    await firebaseStore
      .collection("data")
      .add({ ...data })
      .then(() => true)
      .catch(() => false);
    firebaseStore
      .collection("trackers")
      .add({ ...trackerData })
      .then(() => true)
      .catch(() => false);
  };
  const sighIn = (payload) => {
    firebaseAuth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((res) => {
        console.log(res.user.uid);
        firebaseStore
          .collection("users")
          .doc(res.user.uid).get()
          .then(function(doc) {
            console.log(doc.data())
            payload.setLoading(false);
            setAuth({ isAuthenticated: true, user: doc.data() });
            saveToLocalStorage({ isAuthenticated: true, user: doc.data() });
            findLocalStorage()
            payload.history.push("/dashboard");
          });
          firebaseStore
          .collection("users").doc(res.user.uid).update({last_login: new Date()})
      })
      .catch((e) => {
        toast(e.message);
        payload.setLoading(false);
      });
  };

  const logout = () => {
    setAuth({ isAuthenticated: false, user: {} });
    localStorage.removeItem(MAP_APP_LOCAL_DATA);
  };
  const sighUp = (payload) => {
    firebaseAuth
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then((res) => {
        let currentUser = firebaseAuth.currentUser;
        const userData = {
          user_name: payload.userName,
          email: payload.email,
          // total_token: 0,
          // company_id: currentUser.uid,
        };
        const companyData = {
          company_name: payload.userName,
          company_type: 'consumables',
          // company_id: currentUser.uid,
        };
        currentUser.sendEmailVerification().then(() => {
          // set company data
          firebaseStore
            .collection("company")
            // .doc(currentUser.uid)
            .add(companyData)
            .then((res) => {
              // set user data
              console.log(res.id)
              firebaseStore
                .collection("users")
                .doc(currentUser.uid)
                .set({...userData, company_id: res.id})
                .then((res) => {
                  payload.setLoading(false);
                  payload.history.push("/");
                  toast(
                    "Account created successfully. Please check your email."
                  );
                })
                .catch((error) => {
                  toast(error.message);
                  payload.setLoading(false);
                });
            })
            .catch((error) => {
              toast(error.message);
              payload.setLoading(false);
            });
        });
      })
      .catch((error) => {
        toast(error.message);
        payload.setLoading(false);
      });
  };
  return (
    <UserContext.Provider
      value={{ ...auth, sighIn, logout, sighUp, saveTrackerData }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
